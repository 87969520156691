.card-header {
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #DDDCDC;
    font-weight: bold;
    font-size: 20px;
}

.card-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.top-portion {
    width: 100%;
    background: #ebeaeb86;
    height: 122px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.bottom-portion {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.generator {
    height: 87px;
    width: 143px;
    /* background: rgba(217, 239, 248, 0.60); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.generator>img {
    height: 120px;
}

.on-off-portion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.runtime-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
}

.icon {
    color: black;
    margin-left: 2.5px;
    margin-top: 2px;
}

.icon-section {
    height: 30px;
    width: 30px;
    background: #C9DFFA;
    border-radius: 90%;
}

.runtime-text {
    font-size: 15px;
    font-weight: bold;
    color: #495584;
}

.runtime-value {
    height: 29px;
    width: 90px;
    background: #DEDCDC;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold
}

.bottom-top-portion,
.bottom-bottom-portion {
    display: flex;
    justify-content: space-between;
}

.icon-title-section {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 110px;
}

@media only screen and (max-width: 1537px) and (min-width: 1195px) {
    .item-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 1063px) and (min-width: 767px) {
    .item-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .item-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }
}


/* The switch - the box around the slider */

.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 100px;
    height: 2em;
    border: 1px solid #DDDCDC;
    border-radius: 17px;
    margin: 0px;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    color: #000;
    font-weight: 600;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "On";
    height: 76%;
    width: 48%;
    left: 2%;
    border-radius: 20px;
    background-color: white;
    color: green;
    display: grid;
    align-content: center;
    justify-content: center;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 -1px 0 0 rgba(0, 0, 0, 0.1) inset, 0 -1.31em 1.31em -1.31em rgba(0, 0, 0, 0.3) inset, 0 0 1px 0 rgba(0, 0, 0, 0.1);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: .4s;
}

.slider:after {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    border-radius: 1.71em;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.1));
    z-index: -1;
}

.switch input:checked+.slider {
    background-color: #21f3a3;
    color: #fff;
}

.switch input:checked+.slider:before {
    content: "Off";
    transform: translateX(100%);
    color: red;
}

.switch input {
    display: none;
}